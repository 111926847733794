
    .media-manager {
        width: 100%;
    }
    .menu {
        padding: 5px ;
    }

    .path {
        padding-inline-end: calc(var(--global-margin) * 0.75);

        .uk-button {
            padding: 0 calc(var(--global-margin) * 0.75);
        }
    }
    .media-manager-grid {
        display: grid;
        grid-template-rows: 40px  auto;
        grid-template-columns: 200px  auto;
        grid-gap: 20px;
    }

    .side-nav {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;

    }

    .header {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

    }

    .main {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        min-height: 400px;
    }

    .item {
        cursor: pointer;
        border: 1px solid var(--global-muted-color);


        &:hover {
            border: 1px solid var(--global-primary-background);
            box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
        }
    }

    .item__inner {
        height: 100%;
        width: 100%;
        flex: 100%;
    }
    .item-bottom {
        word-break: break-all;
        max-height: 45px;
        line-height: 1.1;
        margin-top: calc(var(--global-margin) * 0.5);
      //  overflow: hidden;
    }
    .item-image {
        max-width: 100%;
        max-height: 50px;
    }
